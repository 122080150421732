import { path } from "ramda";
import styled from "styled-components";
import { Link } from '@reach/router';

export const Wrapper = styled.div`
  display: flex;
  background-color: ${path(['theme', 'palette', 'introBg'])};
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const Container = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  margin: 0 auto;
  ${(props) => (props.fullHeight ? 'height: 90%;' : '')}

  @media ${path(['theme', 'media', 'tablet'])} {
    max-width: 1650px;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${path(['theme', 'media', 'desktop'])} {
    max-width: 1722px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 20px 0;
`

export const TermsText = styled.div`
  color: ${path(['theme', 'palette', 'jumbo'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
`;

export const TermsLink = styled(Link)`
  text-decoration: underline;
`;