import React, { useMemo, useCallback } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { TranslationKey, useTranslate, useUserContext } from '@nn-virtual-pen/education/data-access';
import { GenericLayout } from '../layouts/generic';
import * as Styled from '../components/terms-of-condition/terms-of-condition.styled';
import { ActionButton, ActionButtonSize, ActionButtonVariant } from '@nn-virtual-pen/ui';
import { RoutePath } from '@nn-virtual-pen/education/utils';
import { defaultTo, find, path, pipe, prop, propEq } from 'ramda';

export const TermsOfCondition = () => {
  const { t } = useTranslate();
  const { configuration } = useUserContext();

  const data = useStaticQuery(graphql`
    query termsOfCondition {
      allCountryInformationJson {
        nodes {
          TermsOfCondition
          Country
        }
      }
    }
  `);

  const getUrl = useCallback(pipe(
    path(['allCountryInformationJson', 'nodes']),
    find(propEq('Country', configuration.country)),
    defaultTo({}),
    prop('TermsOfCondition')
  ), [configuration.country]);

  const url = useMemo(() => getUrl(data), [getUrl, data]);

  return (
    <GenericLayout>
      <Helmet title="Terms of condition" />
      <Styled.Wrapper>
        <Styled.Container fullHeight>
          <iframe
            src={`${url}#toolbar=0&navpanes=0`}
            width="100%"
            height="100%"
          />
        </Styled.Container>
        <Styled.ButtonWrapper>
          <ActionButton
            size={ActionButtonSize.wide}
            variant={ActionButtonVariant.primary}
            title={t(TranslationKey.return_home)}
            onClick={() => void navigate(RoutePath.Home)}>
            {t(TranslationKey.return_home)}
          </ActionButton>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
    </GenericLayout>
  );
};

export default TermsOfCondition
